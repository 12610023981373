import React from "react";

import Col from "react-bootstrap/Col";

export const CardImageRoundedBorder = ({ imageUrl, text, position }) => {
  return (
    <Col className="my-5 mx-5 position_center flex-column">
      <img
        alt="Florian"
        src={imageUrl}
        width="220"
        // height="30"
        className="d-inline-block align-top grow border-image"
      />
      <div
        className={"backgroundBorderFloating-sm backgroundPosition-" + position}
      />
      <p>{text}</p>
    </Col>
  );
};
