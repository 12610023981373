import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";

import { Routes, Route, HashRouter, useLocation } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { AllComponents } from "./pages/AllComponents";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import { Landing } from "./pages/Landing";

import "./styles/App.css";
import "./styles/bootstrap.min.css";
import "./styles/animations.css";
import "./styles/container.css";
import "./styles/index.css";
import "./styles/positions.css";
import "./styles/input.css";
import "./styles/text.css";
import "./styles/images.css";
import { CarnetDeVignes } from "./pages/CarnetDeVignes";
import { Podcast } from "./pages/Podcast";
import { GenerationVignerons } from "./pages/GenerationVignerons";
import { ContactezMoi } from "./pages/ContactezMoi";

import "@fontsource/rakkas";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Header />
    <Wrapper>
      <Routes>
        <React.Fragment>
          <Route path="/" element={<Landing />} />
          <Route path="/carnet-de-vignes" element={<CarnetDeVignes />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route
            path="/generation-vignerons"
            element={<GenerationVignerons />}
          />
          <Route path="/contactez-moi" element={<ContactezMoi />} />
          <Route path="/All-Components" element={<AllComponents />} />
        </React.Fragment>
      </Routes>
    </Wrapper>
    <Footer />
  </HashRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
