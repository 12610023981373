import React from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Logo from "../../logo.svg";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <Navbar bg="white" expand="lg" className="mx-5 mt-2">
      <Container fluid className="d-flex">
        <Navbar.Brand className="flex-row d-flex">
          <img
            alt="logo"
            src={Logo}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            width="200"
            // height="30"
            className="d-inline-block align-top grow"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-2 my-2 my-lg-0 position_center"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link
              onClick={() => navigate("/")}
              className="d-flex flex-row"
              aria-label="Panier"
            >
              <p className="semi-bold ml-2 my-0 grow">Qui suis-je ?</p>
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/carnet-de-vignes")}
              className="d-flex flex-row"
              aria-label="Panier"
            >
              <p className=" semi-bold ml-2 my-0 grow">Carnet de vignes</p>
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/podcast")}
              className="d-flex flex-row"
              aria-label="Panier"
            >
              <p className=" semi-bold ml-2 my-0 grow">Podcast</p>
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/generation-vignerons")}
              className="d-flex flex-row"
              aria-label="Panier"
            >
              <p className=" semi-bold ml-2 my-0 grow">Génération vignerons</p>
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/contactez-moi")}
              className="d-flex flex-row"
              aria-label="Panier"
            >
              <p className=" semi-bold ml-2 my-0 grow">Contactez-moi</p>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
