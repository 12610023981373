import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-duotone-svg-icons";
import Flo from "../assets/images/Flo.webp";
export const GenerationVignerons = () => (
  <>
    <Container style={{ minHeight: "70vh" }} fluid>
      <Row className="w-100 position_center my-5">
        <h2>Génération Vignerons</h2>
      </Row>
      <Row className="background-red-opacity position_center py-5">
        <a
          href="https://generationvignerons.com/"
          rel="noopener noreferrer"
          target="_blank"
          className="w-auto"
        >
          <FontAwesomeIcon
            icon={faLink}
            className="black fa-3x w-auto mx-5 icon-link-red"
          />
        </a>
      </Row>
      <Row className="w-100 position_center my-5 mx-5 px-5">
        <Col>
          <p className="my-4">
            Génération Vignerons est un magazine en ligne pureplayer, gratuit,
            totalement indépendant. L'équipe de rédaction (François, Jean
            Philippe, Audrey et moi) parcourt le monde du vin sous tous ses
            aspects avec en filigrane l'envie de mettre en avant les
            néo-vigneron(ne)s.
          </p>
          <p className="my-4">
            Nous sommes très attentifs à votre confort de lecture et c'est
            pourquoi vous ne verrez ni publicité surgissante, ni bannière
            intrusive, ni contenu publi-rédactionnel sur notre site,
            contrairement à d’autres supports, sites, blogs, qui monnayent leur
            expertise…
          </p>
          <p className="my-4">
            Nous ne dépendons donc d’aucun groupe et disposons d’une totale
            liberté de parole que nous ne privons pas d’utiliser. Excellente
            lecture !
          </p>
        </Col>
        <Col>
          <img src={Flo} width="300" alt="Florian Nunez" />
        </Col>
      </Row>
    </Container>
  </>
);
