import React from "react";
import Card from "react-bootstrap/Card";
const CardImageFilter = ({ image, text, link, ...props }) => {
  return (
    <>
      <a
        href={link !== undefined ? link : "/"}
        className="w-auto grow"
        target="_blank"
        rel="noreferrer noopener"
        id="btn-filterBackgroundCardImage"
      >
        <Card
          style={{ width: "250px", borderRadius: "13px" }}
          className={"text-white p-0 " + props.className}
        >
          <Card.Img
            src={image}
            style={{
              width: "250px",
              height: "250px",
              objectFit: "cover",
              borderRadius: "13px",
            }}
            alt={text}
          />
          <div id="filterBackgroundCardImage" />
          <Card.ImgOverlay className="position_center">
            <Card.Title>
              <h4 className="bold" style={{ marginTop: "150px" }}>
                {text}
              </h4>
            </Card.Title>
          </Card.ImgOverlay>
        </Card>
      </a>
    </>
  );
};

export default CardImageFilter;
