import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessages } from "@fortawesome/pro-duotone-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import Flo from "../assets/images/imageLandingFlo.png";
import CeQuiNousLie1 from "../assets/images/CeQuiNousLie1.png";
import CeQuiNousLie2 from "../assets/images/CeQuiNousLie2.png";

import NotreVision1 from "../assets/images/NotreVision1.png";
import NotreVision2 from "../assets/images/NotreVision2.png";
import NotreVision3 from "../assets/images/NotreVision3.png";
import { CardImageRoundedBorder } from "../components/card-image/cardImageRoundedBorder";
export const Landing = () => {
  return (
    <>
      <Container fluid className="px-5 my-5 py-5">
        <Row className="my-5 py-5">
          <Col md={6} sm={12}>
            <div className="my-2 w-100">
              <h1 className="title">Agent de vigneron(ne)s</h1>
              <h1 className="title">passionné(e)s</h1>
            </div>
            <Row className="d-flex align-items-center my-5 py-5 w-100 ">
              <Col sm={12} md={6} className="position_center my-5">
                <Link to="/contactez-moi" className="w-auto mx-2">
                  <Button
                    variant="primary"
                    className="red-light position_center"
                  >
                    <h5 className="mx-3 white mt-2">Me contacter </h5>
                    <FontAwesomeIcon icon={faMessages} />
                  </Button>{" "}
                </Link>
              </Col>
              <Col sm={12} md={6} className="position_center">
                <a
                  href="https://www.facebook.com/Le.Vin.Coule.a.Flo.Agent"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-auto icon-link mx-3"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-2x" />
                </a>
                <a
                  href="https://www.instagram.com/le_vin_coule_a_flo/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-auto  icon-link mx-3"
                >
                  <FontAwesomeIcon icon={faInstagram} className="fa-2x " />
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={12} className="position_center">
            <div style={{ maxHeight: "600px", maxWidth: "600px" }}>
              <img
                alt="Florian"
                src={Flo}
                // height="30"
                className="d-inline-block align-top grow"
                id="landingImage"
              />
              <div id="backgroundImage" />
              <div id="backgroundBorderFloating" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container id="backgroundQuiSuisJe" className="py-5 px-5 my-5" fluid>
        <Row className="position_center px-5">
          <p className="white text-shadow my-3 px-5">
            Après une expérience professionnelle chez EDF, je m’oriente vers le
            monde du vin, guidé par la passion. Comme passerelle et pour me
            former, je fais tous les niveaux prodigués par le Wine & Spirit
            Education Trust (niveau 4 obtenu en 2021).
          </p>
          <p className="white text-shadow my-3 px-5">
            Désireux de porter l’engagement durable et la philosophie des
            vigneron(ne)s avec lesquels je travaille, je me suis naturellement
            dirigé vers le métier d’agent. Conscient de devoir formaliser cet
            engagement, tous les domaines cultivent leur raisin et font leur
            vins selon les contraintes de l’agriculture biologique.
          </p>
          <p className="white text-shadow my-3 px-5">
            Visage de ces domaines de la Gironde jusqu’à la frontière espagnole,
            j’ai plaisir à chaque rencontre, à chaque discussion, à chaque
            dégustation, d’évoquer un peu, beaucoup, passionnément, ce qui anime
            ces personnes.
          </p>
          <p className="white text-shadow my-3 px-5">Florian Nunez</p>
        </Row>
      </Container>
      <Container className="my-5 py-5">
        <Col>
          <h2 className="position_center">
            Aux côtés des vigneronnes et des vignerons
          </h2>
          <p className="my-5 px-5">
            Derrière une bouteille de vin, il y a une succession d’innombrables
            décisions. Prises par des personnes guidées par leur instinct et la
            vision durable de leur terroir, elles contribuent à définir des
            personnalités singulières et passionnantes.
          </p>
          <p className="my-5 px-5">
            Dans leur quête de l’expression la plus juste de leur terroir, les
            vigneron(ne)s s’attellent à travailler la terre de la façon la plus
            durable possible. Ils suivent donc à minima la philosophie de
            l’agriculture biologique. Dans le chai, c’est la même histoire, le
            moins d’interventions avec en ligne de mire, le but ultime : celui
            de faire très bon.
          </p>
          <p className="my-5 px-5">
            Pour mieux intégrer ce qui se joue dans la vigne et dans le chai, je
            retrouve régulièrement les vigneron(ne)s dans leur région pour
            comprendre, discuter, et parfois leur donner un coup de main. C’est
            dans ces moments singuliers que démarre l’histoire qui nous unit.
          </p>
        </Col>
      </Container>
      <Container className="my-5 py-5">
        <h2 className="position_center">
          De la Champagne au Roussillon, une vision partagée
        </h2>
        <p className="red bold">Ce qui nous lie :</p>
        <Row className="position_center mx-5 px-5">
          <CardImageRoundedBorder
            imageUrl={CeQuiNousLie1}
            position="top-left"
            text="La passion inconditionnelle du vin"
          />
          <CardImageRoundedBorder
            imageUrl={CeQuiNousLie2}
            position="bottom-left"
            text="L’envie de construire une histoire pérenne"
          />
        </Row>
        <p className="red bold">Notre vision :</p>
        <Row className="position_center mx-5 px-5">
          <CardImageRoundedBorder
            imageUrl={NotreVision1}
            position="top-left"
            text="Proposer des cuvées singulières, reflet de leur terroir"
          />
          <CardImageRoundedBorder
            imageUrl={NotreVision2}
            position="bottom-left"
            text="Rendre le plus durable possible notre activité de la vigne jusque dans le verre"
          />
          <CardImageRoundedBorder
            imageUrl={NotreVision3}
            position="top-right"
            text="Animer notre réseau de distribution"
          />
        </Row>
      </Container>
    </>
  );
};
