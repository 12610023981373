import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/images/favicon.ico";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";

const MentionsLegales = ({ showModal, handleCloseModal }) => {
  return (
    <Modal centered show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Mentions légales</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-2">
        <p className="my-4">
          Merci de lire avec attention les différentes modalités d’utilisation
          du présent site avant d’y parcourir ses pages. En connectant sur ce
          site, vous acceptez sans réserve les présentes modalités. Aussi,
          conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour
          la confiance dans l’économie numérique, les responsables du présent
          site internet www.levincouleaflo.com sont :
        </p>
        <h5 className="mt-5 mb-3">Éditeur du Site</h5>
        <p className="my-4">
          Ce site a été réalisé par Kévin SANANIKONE. Vous pouvez me contacter
          par mail à sananikone.kevin@gmail.com ou sur :
          <a
            href="https://kevinsananikone.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://kevinsananikone.com/
          </a>
        </p>
        <p className="my-4">
          Le Vin Coule à Flo Numéro de SIRET : en cours de création Responsable
          éditorial : Florian Nunez 27, rue de Libourne – 33100 Bordeaux
          Téléphone : +33 (0) 6 11 46 63 02 Email :
          florian.nunez.agent@gmail.com Site Web : www.levincouleaflo.com
        </p>
        <h5 className="mt-5 mb-3">Hébergeur</h5>
        <p className="my-4">
          Firebase Hosting Siège social : San Francisco, Californie, États-Unis
          Site web : https://firebase.google.com/
        </p>
        <h5 className="mt-5 mb-3">Conditions d’utilisation :</h5>
        <p className="my-4">
          Le Vin Coule à Flo met en oeuvre tous les moyens dont elle dispose,
          pour assurer une information fiable et mise à jour fiable de ses sites
          internet. Toutefois, des erreurs et des omissions peut survenir.
          L’internaute devra donc s’assurer de l’exactitude des informations
          auprès de Le Vin Coule à Flo, et signaler toutes modifications qu’il
          jugerait utile. Le Vin Coule à Flo n’est en aucun cas responsable de
          l’utilisation faite de ces informations, et de tout préjudice direct
          ou indirect pouvant en découler.
        </p>
        <h5 className="mt-5 mb-3">Cookies :</h5>
        <p className="my-4">
          Le site www.levincouleaflo.com peut être amené à vous demander
          l’acceptation des cookies pour des besoins de statistiques et
          d’affichage. Un cookie est une information déposée sur votre disque
          dur par le serveur du site que vous visitez. Il contient plusieurs
          données qui sont stockées sur votre ordinateur dans un simple fichier
          texte auquel un serveur accède pour lire et enregistrer des
          informations.
        </p>

        <h5 className="mt-5 mb-3">Liens hypertextes :</h5>
        <p className="my-4">
          Les sites internet ne peuvent offrir des liens vers d’autres sites
          internet ou d’autres ressources disponibles sur Internet. Le Vin Coule
          à Flo ne dispose d’aucun moyen pour contrôler les sites en connexion
          avec son site internet et ne répond pas de la disponibilité de tels
          sites et sources externes, ni ne la garantit. Elle ne peut être tenue
          pour responsable de tout dommage, de quelque nature que ce soit,
          résultant du contenu de ces sites ou sources externes, et notamment
          des informations, produits ou services qu’ils proposent, ou de tout
          usage qui peut être fait de ces éléments. Les risques liés à cette
          utilisation incombent pleinement à l’internaute, qui doit se conformer
          à leurs conditions d’utilisation.
        </p>
        <p className="my-4">
          Les utilisateurs, les abonnés et les visiteurs du site internet de Le
          Vin Coule à Flo ne peuvent mettre en place un hyperlien en direction
          de ce site dans l’autorisation expresse et préalable de Le Vin Coule à
          Flo. Dans l’hypothèse où un utilisateur ou visiteur souhaiterait
          mettre en place un hyperlien en direction du site internet de Le Vin
          Coule à Flo, il lui appartiendra d’adresser un email accessible sur le
          site internet afin de formuler sa demande de mise en place d’un
          hyperlien. Le Vin Coule à Flo se réserve le droit d’accepter ou de
          refuser un hyperlien sans avoir à en justifier sa décision.
        </p>
        <h5 className="mt-5 mb-3">Services fournis :</h5>
        <p className="my-4">
          L’ensemble des activités de la société ainsi que ses informations sont
          présentes sur notre site www.levincouleaflo.com.
        </p>
        <p className="my-4">
          Le Vin Coule à Flo s’efforce de fournir sur le site
          www.levincouleaflo.com des informations aussi précices que possible.
          Les renseignements figurant sur le site www.levincouleaflo.com ne sont
          pas exhaustifs et les photos sont à mettre au crédit de Camille
          Pichery. Ils sont donnés sous réserve de modifications ayant été
          apportées depuis leur mise en ligne. Par ailleurs, toutes les
          informations indiquées sur le site www.levincouleaflo.com sont données
          à titre indicatif, et sont susceptibles de changer ou d’évoluer sans
          préavis.
        </p>
        <h5 className="mt-5 mb-3">
          Limitations contractuelles sur les données :
        </h5>
        <p className="my-4">
          Les informations contenues sur ce site sont aussi précises que
          possible et le site est remis à jour à différentes périodes de
          l’année. Il peut toutefois contenir des inexactitudes ou des
          omissions. Si vous constatez une lacune, erreur ou ce qui paraît être
          un dysfonctionnement, merci de bien vouloir le signaler par email, à
          l’adresse florian.nunez.agent@gmail.com, en décrivant le problème de
          la manière la plus précise possible.
        </p>
        <p className="my-4">
          Tout contenu téléchargé se fait aux risques et périls de l’utilisateur
          sous sa seule responsabilité. En conséquence, ne saurait être tenu
          responsable d’un quelconque dommage subi par l’ordinateur de
          l’utilisateur ou d’une quelconque perte de données consécutives au
          téléchargement. De plus, l’utilisateur s’engage à accéder au site en
          utilisant un matériel récent, ne contenant pas de virus et avec un
          navigateur de dernière génération mis-à-jour.
        </p>

        <p className="my-4">
          Les liens hypertextes mis en place dans le cadre du présent site
          internet en direction d’autres ressources présentes sur le réseau
          Internet ne sauraient engager la responsabilité de Le Vin Coule à Flo.
        </p>

        <h5 className="mt-5 mb-3">Propriété Intellectuelle :</h5>
        <p className="my-4">
          Tout le contenu du présent site www.levincouleaflo.com, incluant de
          façon non limitative, les graphismes, images, textes, vidéos,
          animations, sons, logos, gifs et icônes ainsi que leur mise en forme
          sont la propriété exclusive de la société à l’exception des marques,
          logos ou contenus appartenant à d’autres sociétés partenaires ou
          auteurs.
        </p>
        <p className="my-4">
          Toute reproduction, distribution, modification, adaptation,
          retransmission ou publication, même partielle, de ces différents
          éléments est strictement interdite sans l’accord par écrit de Le Vin
          Coule à Flo. Le non-respect de cette interdiction constitue une
          contrefaçon pouvant engager la responsabilité civile et pénale du
          contrefacteur. En outre, les propriétaires des Contenus copiés
          pourraient intenter une action en justice à votre encontre.
        </p>
        <h5 className="mt-5 mb-3">Litiges :</h5>
        <p className="my-4">
          Les présentes conditions du site www.levincouleaflo.com sont régies
          par les lois françaises et toute contestation ou litiges qui
          pourraient naître de l’interprétation ou de l’exécution de celles-ci
          seront de la compétence exclusive des tribunaux dont dépend le siège
          social de la société. La langue de référence, pour le réglement de
          contentieux éventuels, est le français.
        </p>

        <h5 className="mt-5 mb-3">Données personnelles (RGPD) :</h5>
        <p className="my-4">
          Nous nous engageons à appliquer à une politique de confidentialité qui
          protège vos données personnelles.
        </p>
        <p className="my-4">
          Nous ne collectons et n’utilisons que les données personnelles
          nécessaires dans le cadre de notre activité de distribution de vins.
          Nous pouvons être amenés à collecter différentes catégories de données
          personnelles auprès de vous, notamment :<br />
          – information d’identification et de contact (nom, prénom, adresse
          postale et électronique)
          <br />
          – données relatives à vos interactions avec nous (courriers, mails ou
          sur pages de nos réseaux sociaux)
          <br />
        </p>
        <p className="my-4">
          La collecte et le traitement de vos données personnelles sont fondés
          sur : <br />
          – leur nécessité afin d’exécuter le contrat de vente que nous
          concluons avec vous
          <br />
          – le consentement au moment où vous nous les communiquez
          <br />
          – nos obligations légales relatives au commerce, et à celui de
          l’alcool en particulier
          <br />
          – notre intérêt légitime à connaître nos clients et leurs préférences
          afin d’accroître nos ventes, nos communications, optimiser notre
          gestion du risque et éventuellement, défendre nos intérêts en justice.
          <br />
        </p>
        <p className="my-4">
          Nous mettons en place les moyens organisationnels, logiciels,
          techniques et physiques, aptes à assurer la confidentialité et la
          sécurité de vos données personnelles, de manière à empêcher leur
          endommagement, leur effacement ou leur accès par des tiers non
          autorisés.
        </p>
        <p className="my-4">
          Nous conservons vos données personnelles pour la plus longue des
          durées nécessaires au respect des dispositions légales et
          réglementaires applicables ou à une autre durée compte tenu des
          contraintes opérationnelles, de gestion et de la relation clients.
        </p>
        <p className="my-4">
          L’accès à vos données est strictement limité au personnel de la
          société Le Vin Coule à Flo, lequel est soumis à une obligation de
          confidentialité et ne peut utiliser vos données qu’en conformité avec
          nos dispositions contractuelles et la législation applicable.
        </p>
        <p className="my-4">
          Conformément aux dispositions en vigueur de la loi « information et
          libertés » du 6 Janvier 1978 et à compter du 21 décembre 2021, à
          celles du réglement européen n°2016/679/UE du 27 avril 2016 sur la
          protection des personnes physiques à l’égard des traitements des
          données à caractère personnel et sur la libre circulation de
          celles-ci, vous bénéficiez d’un droit d’accès, de rectification, de
          portabilité et d’effacement de vos données, ou encore de limitations
          des traitements opérés sur ces mêmes données.
        </p>
        <p className="my-4">
          Vous pouvez également, pour des motifs légitimes, vous opposer au
          traitement des données vous concernant. Pour l’exercice de vos droits,
          vous pouvez vous adresser à notre société par courrier ou par mail.
        </p>
        <p className="my-4">
          Vous pouvez introduire une réclamation auprès de la CNIL si vous
          considérez que le traitement des données à caractère personnel vous
          concernant constitue une violation à la règlementation. Si vous
          souhaitez avoir des informations supplémentaires sur vos droits, et
          plus particulièrement sur ce dernier droit, dirigez-vous sur le site
          web de la Commission Nationale de l’Informatique et des Libertés.
        </p>
      </Modal.Body>
    </Modal>
  );
};
export const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Container className="background-red px-2 py-2" fluid>
      <Row className="w-50 px-5 py-2 w-100">
        <Col className=" position_center">
          <img src={Logo} width="100" alt="Logo Le vin coule à Flo" />
          <Link to="/contactez-moi" className="position_center mx-5">
            <p className="white grow bold position_center mb-0">Me contacter</p>
          </Link>
          <a
            href="https://www.facebook.com/Le.Vin.Coule.a.Flo.Agent"
            target="_blank"
            className="w-auto icon-link mx-5"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} className="fa-2x white grow" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/le_vin_coule_a_flo/"
            target="_blank"
            className="w-auto  icon-link mx-5"
          >
            <FontAwesomeIcon icon={faInstagram} className="fa-2x white grow" />
          </a>{" "}
        </Col>
      </Row>
      <Row className="position_center w-100 my-2">
        <p className="white">
          L'abus d'alcool est dangereux pour la santé, à consommer avec
          modération
        </p>
      </Row>
      <Row className="position_center w-100">
        <Col md={5} className="position_center">
          <FontAwesomeIcon icon={faLocationDot} className="white mx-2" />
          <p className="white">27, rue de Libourne - 33100 Bordeaux, France</p>
        </Col>
        <Col md={2}>
          <Button
            variant="empty"
            onClick={() => setShowModal(true)}
            className="text-decoration-underline-hover"
          >
            <p className="white">Mentions légales</p>
          </Button>
          <MentionsLegales
            showModal={showModal}
            handleCloseModal={setShowModal}
          />
        </Col>
        <Col md={5} className="position_center">
          <FontAwesomeIcon icon={faPhone} className="white mx-2" />
          <p className="white">+33 (0) 6 11 46 63 02</p>
        </Col>
      </Row>
    </Container>
  );
};
