import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ClosGalerne from "../assets/domaines/ClosGalerne.webp";
import Combrillac from "../assets/domaines/Combrillac.webp";
import DomaineGiraud from "../assets/domaines/DomaineGiraud.webp";
import DomaineRougeot from "../assets/domaines/DomaineRougeot.webp";
import Frea from "../assets/domaines/Frea.webp";
import LeClosRouge from "../assets/domaines/LeClosRouge.webp";
import LesCarmels from "../assets/domaines/LesCarmels.webp";
import LesDolomies from "../assets/domaines/LesDolomies.webp";
import MaisonVeuve from "../assets/domaines/MaisonVeuve.webp";
import MonsLaGraveyre from "../assets/domaines/MonsLaGraveyre.webp";
import PierreWeber from "../assets/domaines/PierreWeber.webp";
import imagePlaceholder from "../assets/domaines/imagePlaceholder.webp";
import CardImageFilter from "../components/card-image-filter/CardImageFilter";
import ClosThierrier from "../assets/domaines/ClosThierrier.webp";
import Baraveou from "../assets/domaines/DomaineBaraveou.webp";
import LaBaronne from "../assets/domaines/LaBaronne.webp";
export const CarnetDeVignes = () => (
  <>
    <Container className="position_center">
      <Row className="mb-5">
        <h2 className="my-5">Aux côtés des vigneronnes et des vignerons</h2>
        <p className="my-3">
          De l’Alsace au Roussillon, en passant par le Jura, la Touraine ou
          encore Bordeaux, j’ai le plaisir de travailler avec des personnalités
          passionnées et passionnantes.
        </p>
        <p className="my-3">
          Pour vous présenter leur philospohie et les cuvées disponibles en
          Aquitaine, j’ai écrit un livret intitulé « carnet de vignes ». Vous
          pouvez l’obtenir sur une simple demande. Je vous l’enverrai par mail
          en .pdf.
        </p>
      </Row>
    </Container>
    <Container fluid className="mx-5 px-5">
      <Row className="mx-5 px-5">
        <h4 className="bold">Alsace</h4>
        <CardImageFilter
          image={PierreWeber}
          text="Domaine Pierre Weber"
          link="https://www.instagram.com/domainepierreweber/"
          className="my-5 mx-2"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Beaujolais</h4>
        <CardImageFilter
          image={imagePlaceholder}
          text="Obora"
          className="my-5 mx-2"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Bordeaux</h4>

        <CardImageFilter
          image={LesCarmels}
          text="les Carmels"
          link="http://domainelescarmels.com/"
          className="my-5 mx-2"
        />

        <CardImageFilter
          image={MonsLaGraveyre}
          link="http://www.monslagraveyre.com/"
          text="Mons la Graveyre"
          className="my-5 mx-2"
        />
        <CardImageFilter image={Frea} text="Fréa" className="my-5" />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Bourgogne</h4>

        <CardImageFilter
          image={DomaineRougeot}
          text="Domaine Rougeot"
          className="my-5 mx-2"
          link="https://www.domainerougeot.com/"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Champagne</h4>

        <CardImageFilter
          image={MaisonVeuve}
          text="Maison Veuve Fourny et Fils"
          className="my-5 mx-2"
          link="https://champagne-veuve-fourny.com/"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Jura</h4>

        <CardImageFilter
          image={LesDolomies}
          text="Les Dolomies"
          className="my-5 mx-2"
          link="http://les-dolomies.com/accueil.php"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Languedoc Roussillon</h4>
        <CardImageFilter
          image={LeClosRouge}
          text="Le Clos Rouge"
          className="my-5 mx-2"
          link="https://www.leclosrouge.fr/"
        />
        <CardImageFilter
          image={LaBaronne}
          text="Chateau La Baronne"
          className="my-5 mx-2"
          link="https://chateaulabaronne.com/"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Sud Ouest</h4>
        <CardImageFilter
          image={Combrillac}
          text="Combrillac"
          className="my-5 mx-2"
          link="https://www.combrillac.fr/"
        />
      </Row>

      <Row className="mx-5 px-5">
        <h4 className="bold">Vallée De La Loire</h4>
        <CardImageFilter
          image={ClosGalerne}
          text="Clos Galerne"
          className="my-5 mx-2"
          link="https://closgalerne.com/"
        />
        <CardImageFilter
          image={ClosThierrier}
          text="Clos Thierrier"
          className="my-5 mx-2"
          link="https://closthierriere.fr/"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Vallée Du Rhône Méridionale</h4>
        <CardImageFilter
          image={DomaineGiraud}
          text="Domaine Giraud"
          className="my-5 mx-2"
          link="http://www.domainegiraud.fr/"
        />
      </Row>
      <Row className="mx-5 px-5">
        <h4 className="bold">Provence</h4>
        <CardImageFilter
          image={Baraveou}
          text="Domaine Baravéou"
          className="my-5 mx-2"
          link="https://www.facebook.com/Baraveou/"
        />
      </Row>
    </Container>
  </>
);
