import React from "react";
import { Container } from "react-bootstrap";

export const AllComponents = () => {
  return (
    <Container>
      <h1> All Components</h1>
      <h2>Title h2</h2>
      <h3>Title h3</h3>
      <h4>Title h4</h4>
      <h5>Title h5</h5>
      <p> Paragraph</p>
    </Container>
  );
};
