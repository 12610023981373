import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export const ContactezMoi = () => (
  <>
    <Container style={{ minHeight: "75vh" }}>
      <Row className="my-5">
        <h2 className="position_center">Contactez-moi</h2>
      </Row>
      <Row className="px-2">
        <p>
          Contactez-moi par téléphone, mail ou encore sur instagram. Je vous
          répondrai dans les meilleurs délais.
        </p>
        <Col md={8}>
          <Row className="my-5 d-flex align-items-center">
            <FontAwesomeIcon icon={faPhone} className="w-auto" />
            <p className="w-auto">+33 (0) 6 11 46 63 02</p>
          </Row>
          <Row className="my-5 d-flex align-items-center">
            <FontAwesomeIcon icon={faInstagram} className="w-auto" />
            <p className="w-auto">@le_vin_coule_a_flo</p>
          </Row>
          <Row className="my-5 d-flex align-items-center">
            <FontAwesomeIcon icon={faEnvelope} className="w-auto" />
            <p className="w-auto">florian.nunez.agent@gmail.com</p>
          </Row>
        </Col>
      </Row>
    </Container>
  </>
);
