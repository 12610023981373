import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/pro-duotone-svg-icons";
import ImagePodcast from "../assets/images/Podcast.webp";
export const Podcast = () => (
  <>
    <Container style={{ minHeight: "75vh" }} fluid>
      <Row className="w-100 my-5">
        <h2 className="position_center w-100">Podcast</h2>
      </Row>

      <Row className="background-red-opacity position_center py-5">
        <a
          href="https://anchor.fm/le-bon-grain-de-livresse "
          rel="noopener noreferrer"
          target="_blank"
          className="w-auto"
        >
          <FontAwesomeIcon
            icon={faLink}
            className="black fa-3x w-auto mx-5 icon-link-red"
          />
        </a>
        <a
          href="https://www.facebook.com/bongrainivresse/"
          rel="noopener noreferrer"
          target="_blank"
          className="w-auto"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            className="black fa-3x w-auto mx-5 icon-link-red"
          />
        </a>
        <a
          href="https://www.instagram.com/bongrainivresse/"
          rel="noopener noreferrer"
          target="_blank"
          className="w-auto"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="black fa-3x w-auto mx-5 icon-link-red"
          />
        </a>
      </Row>
      <Row className="px-5 my-5">
        <Col>
          <p className="my-5">
            Le vin, le jaja, le pinard, le pif. Il existe une ribambelle de
            termes pour désigner ce breuvage que nous aimons tant : le jus de
            raisins fermenté. Et c’est parce que nous l’aimons plus que tout,
            que nous voulons mieux comprendre ce qui fait un bon vin.
          </p>
          <p className="my-5">
            Si le cépage, le climat et le terroir y participent, il y a, avant
            tout, la main des vigneronnes et des vignerons.
          </p>
          <p className="my-5">
            Nous sommes trois copains, et nous avons créé ce podcast pour aller
            à leur rencontre et apprendre de leur expérience. Dans chaque
            épisode, nous vous emmenons dans leurs domaines, pour les interroger
            sur la magie de leur métier et leur vie au milieu des raisins, des
            vignes et des barriques.
          </p>
        </Col>
        <Col className="position_center grow">
          <img src={ImagePodcast} width="500" alt="Le Bon grain de l'ivresse" />
        </Col>
      </Row>
    </Container>
  </>
);
